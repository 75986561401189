import { getPaths, getProps } from "@up/data";
import Head from "next/head";

import SliceRender from "~/components/SliceRender";

export async function getStaticPaths() {
  return getPaths((page) => {
    return !page.uid.match("college-credit");
  });
}

export async function getStaticProps(context) {
  return getProps(context);
}

const Page = function (props) {
  const desc = props.page.data?.description;
  const title = props.page.data?.title;
  const uid = props.uid;
  const catalogueId = props.page.data?.backoffice_catalogue_id;
  const courseName = props.page.data?.is_course_page ? props.uid : null;

  return (
    <>
      <Head>
        {title && <title key="title">{title}</title>}
        {desc && <meta key="description" name="description" content={desc} />}
        {desc && (
          <meta key="og:description" property="og:description" content={desc} />
        )}
        {catalogueId ? (
          <meta
            key="catalogueid"
            name="catalogueid"
            id="catalogueid"
            content={catalogueId}
          />
        ) : null}
        {courseName ? (
          <meta
            key="coursename"
            name="coursename"
            id="coursename"
            content={courseName}
          />
        ) : null}
      </Head>
      {SliceRender(
        props.page.data.page_content,
        props.cohortData,
        props.reusableContentData,
        uid,
        Boolean(catalogueId),
      )}
    </>
  );
};

export default Page;
