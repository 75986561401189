// @ts-nocheck
// Typescript is upset about key differences between
// slicemachine and non slice machine projects
// - jerry July 13, 2023

import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";

import { getInventoryItemsGroupedByCatalogueItems } from "./galaxy-api";

import type { TCatalogueItem, TInventoryItem } from "./types";
import type {
  FilledContentRelationshipField,
  LinkField,
  PrismicDocument,
} from "@prismicio/client";
import type { CreateClientConfig } from "@prismicio/next";
import type { GetStaticProps, GetStaticPropsContext } from "next";

type _Slice = {
  slice_type: string;
  primary: {
    link: {
      id: string;
    };
  };
};

export type NavProps = PrismicDocument<Record<string, any>, string, string>;

// Local Helpers -------------------------------------------------

/* @private */
const _extractInventoryItems = function (catalogueItems: TCatalogueItem[]) {
  return catalogueItems.map((item) => item.cohorts).flat();
};

/* @private */
const _filterOutInventoryItemsPastDeadline = function (
  items: TInventoryItem[],
  deadlineKey: string,
) {
  return items.filter((c) => {
    if (deadlineKey === "registration" || deadlineKey === "application") {
      return new Date(c.applicationDeadline) > new Date();
    } else {
      return new Date(c.enrollmentDeadline) > new Date();
    }
  });
};

/* @private */
const _getReusableContentDocuments = async function (
  client: prismic.Client,
  slices: _Slice[],
) {
  return Promise.all(
    slices
      .filter((s: _Slice) => {
        return s.slice_type === "reusable_content";
      })
      .map((slice: _Slice) => {
        const ID = slice.primary.link.id as string;
        return client.getByID(ID);
      }),
  );
};

/* @private */
const _getCohortDataFromSlice = async function (cohortSlice: any) {
  // hor_program_id from old sites
  const ID_KEY = cohortSlice?.primary?.program_ids
    ? "program_ids"
    : "hor_program_id";

  const catalogueIDs = cohortSlice.primary[ID_KEY] ?? "";
  const deadlineKey = cohortSlice.primary.deadline;

  const catalogueItems =
    await getInventoryItemsGroupedByCatalogueItems(catalogueIDs);
  const cohortData = _filterOutInventoryItemsPastDeadline(
    _extractInventoryItems(catalogueItems),
    deadlineKey,
  );

  return cohortData.sort((a, b) => {
    if (a.startDate > b.startDate) {
      return 1;
    } else if (a.startDate < b.startDate) {
      return -1;
    } else {
      return 0;
    }
  });
};

// Exports -------------------------------------------------------

export function linkResolver(doc: FilledContentRelationshipField) {
  if (doc.uid.match("-college-credit")) {
    switch (doc.type) {
      case "page":
        return `/college-credit/${doc.uid?.replace("-college-credit", "")}`;
      default:
        return null;
    }
  } else {
    switch (doc.type) {
      case "page":
        return `/${doc.uid}`;
      default:
        return null;
    }
  }
}

/*
  hrefResolver for Prismic links
  Depending on the type Web | Document | Media, the data will be different
*/
export const hrefResolver = (data: LinkField) => {
  switch (data.link_type) {
    case "Media":
    case "Web":
      return data.url;
    case "Document":
      return "/" + data.uid;
    default:
      return "#";
  }
};

export const getRepositoryName = function (endpoint: string) {
  return prismic.getRepositoryName(endpoint);
};

export function createClient(
  endpoint: string,
  config: CreateClientConfig = {},
) {
  const client = prismic.createClient(endpoint, {
    ...(config as prismic.ClientConfig),
  });

  const previewConfig = {
    client,
    previewData: config.previewData,
    req: config.req,
  };

  enableAutoPreviews(previewConfig);

  return client;
}

/* @private */
const _defaultFilter = (page: prismic.PrismicDocument) => {
  return Boolean(page);
};

export async function getPaths(
  filter: (page: prismic.PrismicDocument) => boolean = _defaultFilter,
) {
  const client = createClient(
    process.env["NEXT_PUBLIC_PRISMIC_API_URL"] as string,
  );
  const pages = await client.getAllByType("page");
  const resolver = linkResolver as prismic.LinkResolverFunction;

  return {
    fallback: "blocking",
    paths: pages.filter(filter).map((p) => {
      return prismic.asLink(p, { linkResolver: resolver });
    }),
  };
}

export const getPropsForAccountPage = (async () => {
  const client = createClient(
    process.env["NEXT_PUBLIC_PRISMIC_API_URL"] as string,
  );
  const navigation = await client.getByUID("navigation", "navigation-default");

  return {
    props: {
      navigation,
    },
  };
}) satisfies GetStaticProps<{
  navigation: NavProps;
}>;

export async function getProps(context: GetStaticPropsContext, uid?: string) {
  const { params, preview, previewData } = context;
  const UID = uid || params.uid;

  const client = createClient(
    process.env["NEXT_PUBLIC_PRISMIC_API_URL"] as string,
    {
      previewData,
    },
  );

  // Page
  const page = await client.getByUID("page", UID);

  // Navigation
  // If we bring back the ability to have multiple navigations:
  // const navUID = page.data.navigation?.uid ?? "navigation-default";
  const navigation = await client.getByUID("navigation", "navigation-default");

  // Slices
  // Older Prismic sites use different keys (probably slice machine vs classic web editor)
  // when all sites are on slice machine `page_content` can be removed
  const _slices = page.data.slices ?? page.data.page_content;

  // Inventory Items (Cohorts)
  const cohortSlice = _slices.find((s: _Slice) => {
    // "cohort_listing" is from older sites
    return s.slice_type === "cohort_list" || s.slice_type === "cohort_listing";
  });

  // Reusable Content
  // Looks for reusuable content slices
  // Fetches the document referenced in each slice
  const reusableContentData = await _getReusableContentDocuments(
    client,
    _slices,
  );

  return {
    props: {
      cohortData: cohortSlice ? await _getCohortDataFromSlice(cohortSlice) : [],
      navigation,
      page,
      preview: preview ?? null,
      previewData: previewData ?? null,
      reusableContentData,
      uid: UID,
    },
  };
}
