import { isFilled } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";

import { UPLink, isModalTriggerClass } from "@up/ui";

import type { RichTextField } from "@prismicio/client";

export const AttentionBanner = function ({
  className = "",
  field,
}: {
  className?: string;
  field?: RichTextField;
}) {
  if (isFilled.richText(field)) {
    const CLASS = `relative z-10 text-center lg:order-none ${className ?? ""} ${
      isModalTriggerClass(className) ? "cursor-pointer" : ""
    }`;

    return (
      <div className={CLASS} data-element="attention-banner">
        <PrismicRichText
          components={{
            em({ children, key }) {
              return (
                <em className="italic" key={key}>
                  {children}
                </em>
              );
            },
            hyperlink({ children, key, node }) {
              return (
                <UPLink className="underline" key={key} field={node.data}>
                  {children}
                </UPLink>
              );
            },
            strong({ children, key }) {
              return (
                <strong className="font-bold" key={key}>
                  {children}
                </strong>
              );
            },
          }}
          field={field}
        />
      </div>
    );
  }

  return null;
};
