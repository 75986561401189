import { useIsClient } from "usehooks-ts";

import { Navigation as NavigationComponent } from "./_Navigation";
import { AuthNavigation } from "./AuthNavigation";

import type { TNavigationProps } from "./_Navigation";

export function Navigation(
  props: Pick<TNavigationProps, "items" | "Logo" | "uid">,
) {
  const isClient = useIsClient();

  if (isClient) {
    return <AuthNavigation {...props} />;
  }

  return <NavigationComponent {...props} />;
}
