import type { CartRequest, CheckoutState } from "./types";
import type { TCart } from "@up/data";

export type CheckoutAction =
  | { type: "CART_RESET" }
  | { type: "CART_IDLE" }
  | { type: "CART_LOADING" }
  | { type: "CART_LOADING_DID_ERROR"; payload: Error }
  | { type: "CART_DID_LOAD"; payload: TCart }
  | { type: "CART_APPLYING_COUPON" }
  | { type: "CART_APPLYING_COUPON_COMPLETE" }
  | { type: "CART_POLL" }
  | { type: "CART_FINAL_VALIDATE" }
  | { type: "CART_FINAL_VALIDATE_COMPLETE" }
  | { type: "CART_SUBMIT" }
  | { type: "CART_SUBMIT_AS_COMPLETE_DID_ERROR" };

export const checkoutReducer = (
  state: CheckoutState,
  action: CheckoutAction,
): CheckoutState => {
  switch (action.type) {
    case "CART_RESET":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          data: undefined,
          isPolling: false,
          isSubmitting: false,
          isValidating: false,
          status: "loading",
        },
      };

    case "CART_IDLE":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isPolling: false,
          isSubmitting: false,
          isValidating: false,
          status: "success",
        },
      };

    case "CART_LOADING":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isPolling: false,
          isSubmitting: false,
          isValidating: false,
          status: "loading",
        },
      };

    case "CART_DID_LOAD":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          data: action.payload,
          isPolling: false,
          isSubmitting: false,
          status: "success",
        },
      };

    case "CART_LOADING_DID_ERROR":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          error: action.payload,
          isPolling: false,
          isSubmitting: false,
          status: "error",
        },
      };

    case "CART_POLL":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isPolling: true,
          isSubmitting: false,
        },
      };

    case "CART_FINAL_VALIDATE":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isValidating: true,
        },
      };

    case "CART_FINAL_VALIDATE_COMPLETE":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isValidating: false,
        },
      };

    case "CART_SUBMIT":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isPolling: false,
          isSubmitting: true,
        },
      };

    case "CART_SUBMIT_AS_COMPLETE_DID_ERROR":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isSubmitting: false,
          submitAsCompleteDidError: true,
        },
      };

    case "CART_APPLYING_COUPON":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isApplyingCoupon: true,
        },
      };

    case "CART_APPLYING_COUPON_COMPLETE":
      return {
        ...state,
        cart: {
          ...(state.cart as CartRequest),
          isApplyingCoupon: false,
        },
      };

    default:
      return state;
  }
};
