const Chevron = function Chevron() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="9"
      fill="none"
      viewBox="0 0 6 9"
      className="navigation-chevron pointer-events-none"
    >
      <path
        fill="#000"
        d="M1.5 0L.443 1.058 3.877 4.5.443 7.942 1.5 9 6 4.5 1.5 0z"
      ></path>
    </svg>
  );
};

const Arrow = function Arrow({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      fill="none"
      viewBox="0 0 12 13"
      aria-hidden
      className={`navigation-arrow pointer-events-none ${className}`}
    >
      <path
        fill="#000"
        d="M6 .5L4.942 1.558 9.127 5.75H0v1.5h9.127l-4.185 4.193L6 12.5l6-6-6-6z"
      ></path>
    </svg>
  );
};

const Home = function Home({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_206_3668)">
        <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_206_3668">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Close = function Close({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        transform="matrix(-1 0 0 1 24 0)"
        stroke="white"
      />
      <path d="M8.73458 15.3279L15.2617 8.80078" stroke="white" />
      <path d="M8.73458 8.80098L15.2617 15.3281" stroke="white" />
    </svg>
  );
};

export const Icon = function Icon({
  type = "none",
}: {
  type: "internal" | "external" | "parent" | "home" | "close" | "none";
}) {
  switch (type) {
    case "internal":
      return <Arrow />;
    case "external":
      return <Arrow className="-rotate-45" />;
    case "parent":
      return <Chevron />;
    case "home":
      return <Home />;
    case "close":
      return <Close />;
    case "none":
    default:
      return null;
  }
};
