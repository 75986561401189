"use client";

import { show } from "@ebay/nice-modal-react";
import { useRef } from "react";
import { useEventListener } from "usehooks-ts";

import type { ReactNode } from "react";

export function SetupModalTriggers({ children }: { children: ReactNode }) {
  const wrapper = useRef<HTMLDivElement | null>(null);

  const handleEvent = function (_e: Event) {
    const event = _e as MouseEvent;
    const target = event.target as HTMLElement;
    const triggerParent = target.closest("[data-analytics-component]");
    let trigger = "unknown";
    if (triggerParent) {
      trigger = triggerParent.getAttribute(
        "data-analytics-component",
      ) as string;
    }

    const classes: Array<string> = target
      ? Array.from(target.classList.values())
      : [];
    const eventClass = classes.find((c: string) => c.match(/^modal-trigger:/));
    if (!eventClass) {
      return;
    }

    const modalID = eventClass.replace(/^modal-trigger:/, "");
    show(modalID, { modalID, trigger });
  };

  useEventListener("click", handleEvent, wrapper);

  return <div ref={wrapper}>{children}</div>;
}
