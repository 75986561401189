import { SetupEventTracking } from "@up/analytics";
import { isCartOpen } from "@up/cart";
import { useCart } from "@up/checkout";
import { Navigation } from "@up/navigation";
import { useOnetrustTrigger } from "@up/ui";

import { AttentionBanner } from "./attention-banner";
import { CanonicalLink } from "./canonical-link";
import { Main } from "./main";
import { NavigationProfileSelector } from "./navigation-profile-selector";
import { PreviewBanner } from "./preview-banner";
import { TestingEnvBanner } from "./testing-env-banner";

import type { RichTextField } from "@prismicio/client";
import type { ComponentType, PropsWithChildren, ReactElement } from "react";

export type TLayoutProps = PropsWithChildren<{
  attentionBanner?: {
    classes: string;
    text: RichTextField;
  };
  Footer: ComponentType<any>;
  navigation: {
    links: any[];
    Logo: ReactElement;
  };
  preview?: boolean;
  uid: string;
}>;

export const Layout = function Layout({
  attentionBanner,
  children,
  Footer,
  navigation,
  preview = false,
  uid,
}: TLayoutProps) {
  const cart = useCart();
  useOnetrustTrigger();

  return (
    <SetupEventTracking className="flex min-h-dvh flex-col">
      {preview && <PreviewBanner />}
      <TestingEnvBanner />
      <CanonicalLink uid={uid} />

      {attentionBanner ? (
        <AttentionBanner
          className={attentionBanner.classes}
          field={attentionBanner.text}
        />
      ) : null}

      <Navigation
        items={navigation.links || []}
        Logo={navigation.Logo}
        uid={uid}
      />

      <NavigationProfileSelector
        cartCount={cart?.lineItems?.length}
        openCart={() => {
          isCartOpen.set(true);
        }}
      />

      <Main>{children}</Main>

      <Footer />
    </SetupEventTracking>
  );
};
