import { useAuthStore } from "@kaplan-labs/up-auth-api-client";

import { useProfile } from "@up/data";

import { Navigation } from "./_Navigation";

import type { TNavigationProps } from "./_Navigation";

export function AuthNavigation(
  props: Pick<TNavigationProps, "items" | "Logo" | "uid">,
) {
  const auth = useAuthStore();
  const profile = useProfile();

  return <Navigation auth={auth} profile={profile} {...props} />;
}
