import { authStore } from "@kaplan-labs/up-auth-api-client";

import { ownerStore, themeStore } from "@up/data";

import type { ProfilesResponse } from "./types";
import type { TCart } from "@up/data";

export type Config = {
  baseURL: string;
  owner?: string;
  partnerID: string;
};

let _config: undefined | Config = undefined;

export function setNetworkConfig(c: Partial<Config>) {
  _config = {
    ...(_config as Config),
    ...c,
  };
}

function getConfig() {
  const theme = themeStore.get();
  const owner = ownerStore.get();
  const { baseURL } = _config as Config;

  return {
    auth: authStore.get(),
    baseURL,
    owner: owner?.email,
    partnerID: theme.partnerID,
  };
}

export function fetchProfiles() {
  const { auth, baseURL, partnerID } = getConfig();
  const URL = `${baseURL}/profiles/relationships/${partnerID}`;

  return auth.client.get(URL).json<ProfilesResponse>();
}

export function fetchCart(email: string) {
  const { auth, baseURL, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/${email}`;

  return auth.client.get(URL).json<TCart>();
}

export function saveStudent(student: TCart["student"]) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/student`;

  return auth.client
    .patch(URL, {
      json: { student, owner },
    })
    .json<TCart>();
}

export function saveGuardian(guardian: TCart["guardian"]) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/guardian`;

  return auth.client
    .patch(URL, {
      json: {
        guardian,
        owner,
      },
    })
    .json<TCart>();
}

export const addCoupon = function (code: string) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/coupons`;

  return auth.client
    .post(URL, {
      json: {
        code,
        owner,
      },
    })
    .json<string>();
};

const removeCoupon = function (code: string) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/coupons/${code}`;

  return auth.client
    .delete(URL, {
      json: {
        owner,
      },
    })
    .json<string>();
};

const addLineItem = function (id: string) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/items`;
  const json = {
    _id: id,
    owner,
    partner: {
      _id: partnerID,
    },
  };

  return auth.client
    .post(URL, {
      json,
    })
    .json<string>();
};

// TODO: return type needs work
export const removeLineItem = function (_id: string) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/items/${_id}`;

  return auth.client
    .delete(URL, {
      json: {
        owner,
      },
    })
    .json<TCart>();
};

export const submitAsComplete = function () {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/complete`;

  return auth.client
    .post(URL, {
      json: { owner },
    })
    .json();
};

export const finalValidate = function (cart: TCart) {
  const { auth, baseURL, owner, partnerID } = getConfig();
  const URL = `${baseURL}/checkout_bff/${partnerID}/validate`;

  return auth.client
    .post(URL, {
      json: {
        owner,
        ...cart,
      },
    })
    .json<TCart>();
};

export const network = {
  setNetworkConfig,

  addLineItem,
  removeLineItem,

  addCoupon,
  removeCoupon,

  saveGuardian,
  saveStudent,

  fetchProfiles,
  fetchCart,
  finalValidate,
  submitAsComplete,
};

export type NetworkType = typeof network;
