// TYPE -----------------------------------------

export type TApplicationStatus =
  (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

// ENUM -----------------------------------------

export const ApplicationStatus = {
  Abandoned: "abandoned",
  AdminCompleted: "adminCompleted",
  Approved: "approved",
  Denied: "denied",
  OnHoldCX: "onHoldCX",
  OnHoldUser: "onHoldUser",
  PendingReview: "pendingReview",
  UserStarted: "userStarted",
} as const;

// HELPERS --------------------------------------

export function applicationIsIncomplete(appStatus?: TApplicationStatus) {
  return (
    !appStatus ||
    appStatus === ApplicationStatus.UserStarted ||
    appStatus === ApplicationStatus.Abandoned
  );
}

export function applicationIsPendingReview(appStatus?: TApplicationStatus) {
  return (
    appStatus === ApplicationStatus.PendingReview ||
    appStatus === ApplicationStatus.OnHoldCX ||
    appStatus === ApplicationStatus.OnHoldUser
  );
}

export function applicationIsApproved(appStatus?: TApplicationStatus) {
  return (
    appStatus === ApplicationStatus.Approved ||
    appStatus === ApplicationStatus.AdminCompleted
  );
}

export function applicationIsDenied(appStatus?: TApplicationStatus) {
  return appStatus === ApplicationStatus.Denied;
}
