import type { TCatalogueItem } from "./types";

/**
 * @param {string} catalogueIds - A string with comma separated ids
 */
export async function getInventoryItemsGroupedByCatalogueItems(
  catalogueIds: string,
) {
  const SANITIZED_IDS = catalogueIds.replace(/ /g, "").split(",").toString();

  const BASE = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

  const PARTNER_ID = process.env[
    "NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID"
  ] as string;

  const url =
    BASE + `/app-ac/${PARTNER_ID}/search?catalogueIds=${SANITIZED_IDS}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const json = await response.json();
      return json as Array<TCatalogueItem>;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

let _allItems: Array<TCatalogueItem> = [];
export async function getAllInventoryItems() {
  if (_allItems.length) {
    return _allItems;
  }

  const BASE = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

  const PARTNER_ID = process.env[
    "NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID"
  ] as string;

  const url = `${BASE}/app-ac/${PARTNER_ID}`;

  const response = await fetch(url);
  const json = await response.json();
  /*
    "programs" was an old term from the previous system that
    made it into the Galaxy API. Hiding that at the
    fetch level.
  */
  _allItems = json.programs as Array<TCatalogueItem>;
  return _allItems;
}
